import React from "react";
import {NavLink,Link} from "react-router-dom";
import {Registration} from "./Registration";
import {Homepage} from "../pages/Homepage";
import {Start} from "./Start";
import '../css/button.css'

export const Login = () => {
    React.useEffect(()=>{
        console.log('1')
    },[])
    return (<>
            <Homepage/>
        <div className="container-fluid">
            <div className="row justify-content-center m-5">

                <form action="">
                    <input type="text"  placeholder='Login' type="email" className=" form-control"/>
                    <br/>
                    <input type="login" placeholder='Password' type="password" className="form-control"/>
                    <NavLink to='my_account'><button type="button" placeholder='Войти' className="btn btn-dark">Come in</button></NavLink>
                </form></div>
            <div>
                <NavLink to='/'>  <div className='homeButton'>To home <span className='arrow'>➤</span></div></NavLink>
            </div>
        </div>
        </>
    )
}
