import React from "react";
import {imgd} from "../img/visa.png";

let classes = {
    'row': 'row registration-home-room-account justify-content-center',
    'center': 'align-content-center',
}


export let Room = ({name, fname}) => {
    return (
        <div className="container-fluid">

            <div className={`${classes.row} ${classes.center}`}>
                <div className='col-12 text-center'><h1 className='name name-account'></h1></div>
                <div className='col-12 text-center'>
                    <div className={`${classes.row} ${classes.center} `}>

                        <div className='col-12 text-center'>



                        </div>
                        <div className='credit_card'>
                            <h1 className='name name_logo' translate='no'>pay€rowd</h1>
                            <div> <img src={require('../img/visa.png')} alt="..." className="visamc"/>{imgd}</div>
                            <div className='name chip_card'></div>

                            <div className='name num_card'>xxxx xxxx xxxx xxxx</div>
                            <div className='name gult' translate='no'><span className='gult_d'>Debit</span> Gültig bis:12/25 </div>

                            <div className='name name_logo'><span className='names'>{name}</span><span className='names'> {fname}</span></div>

                        </div>
                        {/*<h3 className='name'>{name}</h3>*/}
                    </div>
                </div>
            </div>

        </div>)
}