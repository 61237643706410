import React from "react";
import "../css/App.css";


export const Move = () => {

    let data = new Date();

    return(<>
    <div className='row App flex-column'>
        <div className='col'>{data.getDate()}.{data.getMonth()+1}.{data.getFullYear()}</div>
        <div className='col'>no operations</div>
    </div>

    </>)
}
