import React, {useRef} from "react";
import {NavLink, Route} from "react-router-dom";
import ImageUploads from "./fotoupload";


let local = localStorage

export const Questions = ({data, startPosition, name, sname, localname}) => {

    const [nexts, setNext] = React.useState(1)
    const [email, setEmail] = React.useState('')
    const [personName, setPersonName] = React.useState('')
    const nameRef = useRef();
    const fnameRef = useRef();
    let handleChange = (event) => {

        console.log(event.target.id, 'EVENT')
        setEmail(event.target.value)
        console.log(email)

        if(event.target.id == 'name'){
            name(event.target.value)
            setPersonName(event.target.value)
            local.setItem('name', nameRef.current.value)
            console.log(nameRef.current.value)

        }
        if(event.target.id == 'familiaName'){
            sname(event.target.value)
            local.setItem('fname', fnameRef.current.value)
        }
    }

    let nextQ = () => {
        setNext((num) => num + 1);
    }

    let position = () => {
        startPosition('/account')
    }

    return (
        <>



            <div className='container'>
                {nexts == 2 &&
                <div className="row justify-content-center m-5">
                    {/*<label htmlFor="exampleInputEmail1">Введите Емайл</label>*/}
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder='Enter email'
                           onChange={handleChange}/>
                    {/*<div className='text-center' onClick={nextQ}>далее</div>*/}

                    <div className="row justify-content-center m-5">
                        {/*<label htmlFor="exampleInputEmail1">Введите Телефон</label>*/}
                        <input type="email" className="form-control" id="phone" aria-describedby="emailHelp" placeholder='Enter phone'
                               onChange={handleChange}/>
                        {/*<div className='text-center' onClick={nextQ}>далее</div>*/}
                        <div className="row justify-content-center m-5">
                            {/*<label htmlFor="exampleInputEmail1">Придумайте пароль</label>*/}
                            <input type="email" className="form-control" id="pass" aria-describedby="emailHelp" placeholder=' think of password'
                                   onChange={handleChange}/>
                            <div className='homeButton' onClick={nextQ}>next<span className='arrow'>➤</span></div>
                        </div>
                    </div>

                </div>
                    }


                {/*{nexts == 2 && <div className="row justify-content-center m-5">*/}
                {/*    <label htmlFor="exampleInputEmail1">Введите Телефон</label>*/}
                {/*    <input type="email" className="form-control" id="phone" aria-describedby="emailHelp"*/}
                {/*           onChange={handleChange}/>*/}
                {/*    <div className='text-center' onClick={nextQ}>далее</div>*/}
                {/*</div>}*/}

                {/*{nexts == 3 && <div className="row justify-content-center m-5">*/}
                {/*    <label htmlFor="exampleInputEmail1">Придумайте пароль</label>*/}
                {/*    <input type="email" className="form-control" id="pass" aria-describedby="emailHelp"*/}
                {/*           onChange={handleChange}/>*/}
                {/*    <div className='text-center' onClick={nextQ}>далее</div>*/}
                {/*</div>}*/}

                {nexts == 1 && <div className="row justify-content-center m-5">
                    {/*<label htmlFor="exampleInputEmail1">Ваше Имя</label>*/}
                    <input type="email" ref={nameRef} className="form-control" id="name" placeholder='Your name'
                           onChange={handleChange}/>
                    {/*<div className='text-center' onClick={nextQ}>далее</div>*/}
                    <div className="row justify-content-center m-5">
                        {/*<label htmlFor="exampleInputEmail1">Ваша Фамилия</label>*/}
                        <input type="email" ref={fnameRef} className="form-control" id="familiaName" placeholder='Your last name'
                               onChange={handleChange}/>
                        <div className='homeButton' onClick={nextQ}>next<span className='arrow'>➤</span></div>
                    </div>
                </div>
                }

                {/*{nexts == 3 && <div className="row justify-content-center m-5">*/}
                {/*    <label htmlFor="exampleInputEmail1">Ваша Фамилия</label>*/}
                {/*    <input type="email" ref={fnameRef} className="form-control" id="familiaName" aria-describedby="emailHelp"*/}
                {/*           onChange={handleChange}/>*/}
                {/*    <div className='text-center' onClick={nextQ}>далее</div>*/}
                {/*</div>}*/}
                {/*<div>{email}</div>*/}
            </div>


            {nexts == 3 && <div className="row justify-content-center m-auto">

                <div className='text-center'>
                    <ImageUploads title={'Фото 1'}/>
                <div className='' onClick={nextQ}>next<span className='arrow' onClick={nextQ}>➤</span></div>
                </div>
            </div>}


            {/*{nexts == 4 && <div className="row justify-content-center m-auto">*/}
            {/*    <ImageUploads title={'Фото 2'}/>*/}
            {/*    <div className='text-center'>*/}



            {/*    </div>*/}
            {/*</div>}*/}

            {nexts == 4 && <div className="row justify-content-center m-5 m-auto">
                <div className='text-center mjo'>
                <label htmlFor="exampleInputEmail1">Enter </label>
                <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                       onChange={handleChange}/>
                <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                       onChange={handleChange} placeholder='Account owner'/>
                <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                       onChange={handleChange} placeholder='Name'/>
                <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                       onChange={handleChange} placeholder='Last name'/>
                <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                       onChange={handleChange} placeholder='Date of Birth'/>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                           onChange={handleChange}/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                           onChange={handleChange} placeholder='Place of Birth'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                           onChange={handleChange} placeholder='Citizenship'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                           onChange={handleChange} placeholder='Activities'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                           onChange={handleChange} placeholder='Self employed'/>

                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Employee'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Activities'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Does not work'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Retiree'/>

                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='An identification number'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Place of permanent residence'/>


                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Primary residence (street, house number, postcode, city)'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='If the primary or secondary address is in a high-risk country'/>
                    <input type="text" className="form-control" id="e" aria-describedby="emailHelp"
                            onChange={handleChange} placeholder='Reason for staying in Germany'/>
                    


                    <NavLink to={"/account"} onClick={position}><div className='' onClick={position}>Next<span className='arrow'>➤</span></div></NavLink>


                </div>
            </div>}



        </>


    );
}
