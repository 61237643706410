import React from "react";
import "../css/App.css";


export const Credit = () => {
    return(<>
        <div className='row App flex-column'>
            <div className='col'>credit limit</div>
            <div className='col'>100 Euro</div>
        </div>
    </>)
}
