import React from "react";
import "../css/App.css";


export const Balans = () => {
    return(<>
    <div className='row App flex-column'>
        <div className='col'>Current balance</div>
        <div className='col'>0 Euro</div>
    </div>

    </>)
}
