import React from "react";
import "../css/App.css";


export const MyTransaction = () => {
    return (<>
        {/*<div className='row App'>*/}
        {/*    <div className='col'>C карты:</div>*/}
        {/*    <div className='col'><input type="text"/></div>*/}
        {/*</div>*/}
        {/*<div className='row App'>*/}
        {/*    <div className='col'>На карту:</div>*/}
        {/*    <div className='col'><input type="text"/></div>*/}
        {/*</div>*/}
        {/*<div className='row App'>*/}
        {/*    <div className='col'>Сумма:</div>*/}
        {/*    <div className='col'><input type="text"/></div>*/}
        {/*</div>*/}
        {/*<div className='row App'>*/}
        {/*    <div className='col'>Назначение:</div>*/}
        {/*    <div className='col'><input type="text"/></div>*/}
        {/*</div>*/}
<div className='row justify-content-center'>
        <div className="col justify-content-center m-5">
            <label htmlFor="exampleInputEmail1">From card:</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value='xxxx xxxx xxxx xxxx'/>
            <div className='text-center'></div>
        </div>
        <div className="col justify-content-center m-5">
            <label htmlFor="exampleInputEmail1">To card:</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"/>
            <div className='text-center'></div>
        </div>
        <div className="col justify-content-center m-5">
            <label htmlFor="exampleInputEmail1">Amount:</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"/>
            <div className='text-center'></div>
        </div>
        <div className="col justify-content-center m-5">
            <label htmlFor="exampleInputEmail1">Destination of payment:</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"/>
            <div className='text-center'></div>
        </div>

</div>
    </>)
}
