import React from "react";
import {NavLink} from "react-router-dom";
import {Registration} from "./Registration";
import {Homepage} from "../pages/Homepage";
import {Start} from "./Start";
import '../css/button.css'

export const Home = () => {
    React.useEffect(()=>{
        console.log('1')
    },[])
    return (<>
            <Homepage/>
        <div className="container-fluid">
            <div className="row">
                <div className="col">


                </div>
            </div>
            <div className="row text-center">
                <div className="col-12 ">

                    <form className='mini'>
                        <p className=''><select name="list1">
                            <option>English</option>
                        </select></p>

                    </form>
                    <NavLink to={"/reg"}>
                        <div className='homeButton'>Create an account  <span className='arrow'>➤</span></div>

                    </NavLink>

                </div>

                <div className="col-12">
                    <NavLink to={"/login"}>
                        <div className='homeButton homeButton_open'>Sign in  <span className='arrow'>➤</span></div>
                    </NavLink>

                </div>

                {/*<div className="col-12">*/}
                {/*    <NavLink to={"/my"}>*/}
                {/*        <div className='homeButton homeButton_open'>Заглушка  <span className='arrow'></span></div>*/}
                {/*    </NavLink>*/}

                {/*</div>*/}
            </div>
        </div>
        </>
    )
}
