import React, {useEffect} from "react";
import {imgd} from "../img/visa.png";
// import '../scss/card.scss'
// import Movie from '../components/Movie'

let classes = {
    'col': 'col registration-home justify-content-center',
    'center': 'align-content-center',
}




export let Start = ({name, sname}) => {


    return (
    <div className="container-fluid">
        <div className='row'>

            <div className={`${classes.col} ${classes.center}`}>
                <div className="col-12 text-center pt-5"><h3 className='name'>Регистрация</h3></div>
                <div className='col-12 text-center drive mt-4'>
                    <div className='credit_card drive_but'>
                        <h1 className='name name_logo' translate='no'>pay€rowd</h1>
                        <div> <img src={require('../img/visa.png')} alt="..." className="visamc"/>{imgd}</div>
                        <div className='name chip_card'></div>

                        <div className='name num_card'>xxxx xxxx xxxx xxxx</div>
                        <div className='name gult' translate='no'><span className='gult_d'>Debit</span> Gültig bis:12/25 </div>

                        <div className='name name_logo'>
                            <span className='names'>{name}</span>
                            <span className='names'> {sname}</span>
                        </div>

                    </div>
                </div>

                {/*<h3 className='name'>{name}</h3>*/}
            </div>
        </div>
    </div>)
}