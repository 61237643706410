import React, {useEffect} from "react";
// import '../scss/card.scss'
// import Movie from '../components/Movie'
import {imgd} from '../img/visa.png'
import {card_logo} from '../img/logo_PG.png'
let classes = {
    'col': 'col justify-content-center',
    'center': 'align-content-center',
}




export let Start_two = ({name, sname}) => {


    return (
    <div className="container mt-4">
        <div className='row'>

            <div className={`${classes.col} ${classes.center}`}>

                <div className='col-12 text-center drive'>
                    <div className='col-12 col-sm-7 col-md-7 col-lg-4 mcredit_card drive_but mcredit_card drive_but'>
                    <h1 className='name name_logo' translate='no'><img className="pc" src={require('../img/logo_PG.png')} alt="..." />{imgd}</h1>
                        <div> <img src={require('../img/visa.png')} alt="..." className="visamc"/>{imgd}</div>
                        <div className='name chip_card'></div>

                    <div className='name num_card'>xxxx xxxx xxxx xxxx</div>
                        <div className='name gult' translate='no'><span className='gult_d'>Debit</span> Gültig bis:12/25 </div>

                        <div className='name name_logo'>

                            <span className='names'>{name}</span>
                            <span className='names'> {sname}</span>
                        </div>

                    </div>
                </div>

                {/*<h3 className='name'>{name}</h3>*/}
            </div>
        </div>
    </div>)
}
