import React from "react";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import {presentationMode} from '../components/Slider'
import {Start_two} from "./Start2";

let classes = {
    'row': 'row registration-home justify-content-center',
    'center': 'align-content-center',
}




export let Homepage = () => {
    return (<>
        <div className='mcard'><Start_two/></div>
    <div className="container-fluid text-center">

        <div>


            <div className={`${classes.row} ${classes.center} `}>

                <div className='col-8'>

                    <Carousel showArrows={false} showIndicators={false} showThumbs={false} dynamicHeight={false} autoPlay={false} showStatus={false}>
                        <div key="slide1">

                            <h1 className='name' translate='no'><img className="home_logo_png" src={require('../img/logo_PG.png')} alt="..." /></h1>
                        </div>

                    </Carousel>

                </div>
                <div className='col-8'>


                    <h3 className='name losung'>Euro bank account in 4 steps</h3>
                    <h4 className='name losung2'>available to everyone</h4>
                </div>
            </div>


        </div>

    </div>

        </>)
}
