import React from "react";
import "../css/App.css";


export const MyMove = () => {

    let data = new Date();

    return(<>
        <div className='row App flex-column'>
            <div className='col'>{data.getDate()}.{data.getMonth()+1}.{data.getFullYear()}</div>
            <div className='col'> Transaction in xxxx xxxx xxxx xxxx 120 Euro</div>
        </div>
        <div className='row App flex-column'>
            <div className='col'>{data.getDate()-1}.{data.getMonth()+1}.{data.getFullYear()}</div>
            <div className='col'>Transaction in xxxx xxxx xxxx xxxx 58 Euro</div>
        </div>
        <div className='row App flex-column'>
            <div className='col'>{data.getDate()-2}.{data.getMonth()+1}.{data.getFullYear()}</div>
            <div className='col'>Transaction in xxxx xxxx xxxx xxxx 90 Euro</div>
        </div>


    </>)
}
