import React from "react";
import "../css/App.css";


export const Add = () => {
    return(<>
    <div className='row App flex-column'>
        <div className='col'> recharge the balance:</div>
        <div className='col'><input type="text"/></div>
    </div>

    </>)
}
