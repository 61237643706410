import React from "react";
import Loader from "react-loader-spinner";
import {Balans} from "../components/balans"
import {Credit} from "../components/credit"
import {Add} from "../components/add"
import {Transaction} from "./transaction";
import {Move} from "./move";

export let Cardresult = ({info, show, isStatus}) => {
    return <>
        <div className='brd'>
        <div className='justify-content-center App'>
            <h1>{info}</h1>
            {show &&
            <>
                <div><input type="text" placeholder='input activation code'/></div>
                <button onClick={isStatus}> Confirm </button>
            </>


            }






            {info == 'Перевод средств' && <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={''} //3 secs

            />}


        </div>

        <div className=' col justify-content-center'>
            {info == 'Your balance' && <Balans/>}
            {info == 'Credit' && <Credit/>}
            {info == 'Recharge' && <Add/>}
            {info == 'Transfer' && <Transaction/>}
            {info == 'Transaction' && <Move/>}


        </div>
        </div>
    </>
}
