import React, {useEffect} from "react";
import {BrowserRouter, NavLink, Route} from "react-router-dom";
import {My_Card} from "../components/my_card";
import {My_Cardresult} from "../components/my_cardResult";
import {MyRoom} from "./MyRoom";
import {Home} from "./Home";
import Loader from 'react-loader-spinner'


export const My_Cards = () => {
    const [info, setinfo] = React.useState('')
    const [status, setStatus] = React.useState('Не активен')
    const [active, setActive] = React.useState('green')
    const [show, setShow] = React.useState(true)
    const [name, setName] = React.useState('')
    const [fName, setFname] = React.useState('')

    const menuItem = [['Your balance','Credit','Recharge'], ['Transfer'], ['Transaction'], ['Put key-code']]

    useEffect(()=>{
        if(localStorage.name !== undefined )
            setName(localStorage.name)
        if(localStorage.fname !== undefined )
            setFname(localStorage.fname)

    })

    let dataSet = (titles) => {
        console.log(titles)
        setinfo(titles)
    }

    let isStatus = () => {
        setStatus('Статус активен')
        setActive('green')
        setShow(false)
    }

    return (


        <>
            <MyRoom name={name} fname={fName}/>
            <My_Cardresult info={info} isStatus={isStatus} show={show}/>
            <div className='container-fluid row justify-content-center align-content-around'>


                {show && <My_Card dataSet={dataSet} info={'Your balance - '} buttonName='Balance ' activeStatus={active}
                                menuItem={menuItem[0]}/>}
                {show && <My_Card dataSet={dataSet} info={"Transfer"} buttonName='Transfer' activeStatus={active}
                                menuItem={menuItem[1]}/>}
                {/*Прокинуть функцию, которая меняет состояния для отображения формы*/}
                {!show &&
                <My_Card dataSet={dataSet} info={""} buttonName={status} activeStatus={active} menuItem={menuItem[3]}/>
                }
                {show &&
                <My_Card dataSet={dataSet} info={"Account"} buttonName="My account" activeStatus={active}
                      menuItem={menuItem[2]}/>
                }


                {/*<button onClick={()=>dataSet('from')}>function</button>*/}
                {/*<button onClick={()=>setinfo('from')}>function2</button>*/}
                {/*       Добавить компонент, для отображения информации по клику через Card*/}


            </div>



        </>)
}
