import React from "react";
import Loader from "react-loader-spinner";
import {MyBalans} from "./my_balans";
import {MyCredit} from "./my_credit"
import {MyAdd} from "../components/my_add"
import {MyTransaction} from "./my_transaction";
import {MyMove} from "./my_move";

export let My_Cardresult = ({info, show, isStatus}) => {
    return <>
        <div className='brd'>
        <div className='justify-content-center App'>
            <h1>{info}</h1>



        </div>

        <div className=' col justify-content-center'>
            {info == 'Your balance' && <MyBalans/>}
            {info == 'Credit' && <MyCredit/>}
            {info == 'Recharge' && <MyAdd/>}
            {info == 'Transfer' && <MyTransaction/>}
            {info == 'Transaction' && <MyMove/>}

        </div>
        </div>
    </>
}
