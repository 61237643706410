import React from "react";
import "../css/App.css";


export const MyBalans = () => {
    return(<>
    <div className='row App flex-column'>
        <div className='col'>Current balance</div>
        <div className='col'>1000 Euro</div>
    </div>

    </>)
}
