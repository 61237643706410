import React from "react";
import {NavLink, Route} from "react-router-dom";
import {Room} from "../pages/Room";
import {Home} from "../pages/Home";

export const My_Card = ({dataSet, info, buttonName, activeStatus, menuItem}) => {

    let item = menuItem.map(items => <div><button onClick={()=>dataSet((Object.values({items}).toString()))} className='btn btn btn-light _button_menu mb-2'>{items}</button></div>)


    return (
        <>


            <div className="col-4 col-xs-4 justify-content-center text-center mt-3 _menu">
                <svg className="bd-placeholder-img rounded-circle" width="80" height="80"
                     xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false"
                     role="img" aria-label="Placeholder: 140x140"><title>Placeholder</title>
                    <rect width="100%" height="100%" fill={activeStatus}></rect>
                    <text x="20%" y="50%" fill="#777" dy=".3em"></text>

                </svg>

                <div className=''>{buttonName}</div>
                {/*<p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies*/}
                {/*    vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo*/}
                {/*    cursus magna.</p>*/}
                {activeStatus !== 'grey' && <button className="btn btn-secondary" role="button">»</button>}

                <ul className='__menu'>
                    {item}
                </ul>
            </div>
        </>
    )
}