import React from 'react';
import logo from './logo.svg';
import './css/App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Home} from './pages/Home';
import {Registration} from './pages/Registration';
import {Cards} from "./pages/Cards";
import {My_Cards} from "./pages/My_Cards";
import {Presite} from "./pages/preSite";
import {Login} from "./pages/Login";
import {Start} from "./pages/Start";
import {Room} from "./pages/Room";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

let classes = {
    'row': 'row registration-home justify-content-center',
    'center': 'align-content-center',
}


function App() {

    const [start, setStart] = React.useState('/')

    const [ich, setIch] = React.useState('')

    let dataIch = (namesss) => {
        setIch(namesss)
    }

    let startPosition = (position) => {
        setStart(position)
    }

    React.useEffect(() => {
        let url = window.location.href;
        url = url.substr(21)
        setStart(url)
    }, [])

    return (

        <BrowserRouter>
            {/*{start == "/" &&*/}
            {/*    <Start/>*/}
            {/*}*/}

            {/*{start == "/account" &&*/}
            {/*<Room/> }*/}

            <Route path={'/'} exact component={() => <Home/>}/>
            <Route path={'/login'} exact component={() => <Login/>}/>
            <Route path={'/reg'} exact component={() => <Registration position={startPosition} />}/>
            <Route path={'/account'} exact component={() => <Cards/>}/>
            <Route path={'/my_account'} exact component={() => <My_Cards/>}/>
            {/*<Route path={'/my'} exact component={() => <Presite/>}/>*/}



        </BrowserRouter>

    );


}

export default App;
