import React, {Component} from 'react';
import '../css/reg.css';

class ImageUploads extends React.Component {
    constructor(props) {
        super(props);
        this.state = { file: '', imagePreviewUrl: '' };
    }

    _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
        console.log('handle uploading-', this.state.file);
    }

    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result });

        };

        reader.readAsDataURL(file);
    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = React.createElement("img", { src: imagePreviewUrl });
        } else {
            $imagePreview = React.createElement("div", { className: "previewText" }, "Please select an Image for Preview");
        }

        return (
            React.createElement("div", { className: "previewComponent" },
                React.createElement("div", { onSubmit: e => this._handleSubmit(e) },
                    React.createElement("input", { className: "fileInput",
                        type: "file",
                        onChange: e => this._handleImageChange(e) }),
                    React.createElement("button", { className: "submitButton",
                        type: "submit",
                        onClick: e => this._handleSubmit(e) }, "Upload Document")),

                React.createElement("div", { className: "imgPreview" },
                    $imagePreview)));



    }}

export default ImageUploads;

// <div className='previewComponent'>
//     <form onSubmit={(e)=>(this._handleSubmit(e))}>
//         <input type="file" className='fileInput' onChange={(e)=>this._handleImageChange(e)}/>
//         <button type='submit' onClick={(e)=>this._handleSubmit(e)}>Upload</button>
//
//     </form>
// </div>
