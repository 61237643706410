import React, {useEffect} from "react";
import {BrowserRouter, NavLink, Route} from "react-router-dom";
import {Card} from "../components/card";
import {Cardresult} from "../components/cardResult";
import {Room} from "./Room";
import {Home} from "./Home";
import Loader from 'react-loader-spinner'


export const Cards = () => {
    const [info, setinfo] = React.useState('')
    const [status, setStatus] = React.useState('Not active')
    const [active, setActive] = React.useState('grey')
    const [show, setShow] = React.useState(true)
    const [name, setName] = React.useState('')
    const [fName, setFname] = React.useState('')

    const menuItem = [['Your balance','Credit','Recharge'], ['Transfer'], ['Transaction'], ['Put key-code']]

    useEffect(()=>{
        if(localStorage.name !== undefined )
            setName(localStorage.name)
        if(localStorage.fname !== undefined )
            setFname(localStorage.fname)

    })

    let dataSet = (titles) => {
        console.log(titles)
        setinfo(titles)
    }

    let isStatus = () => {
        setStatus('Active')
        setActive('green')
        setShow(false)
    }

    return (


        <>
            <Room name={name} fname={fName}/>
            <Cardresult info={info} isStatus={isStatus} show={show}/>
            <div className='container-fluid row justify-content-center align-content-around'>


                {!show && <Card dataSet={dataSet} info={'Your balance - '} buttonName='Balance ' activeStatus={active}
                                menuItem={menuItem[0]}/>}
                {!show && <Card dataSet={dataSet} info={"Transfer"} buttonName='Transfer' activeStatus={active}
                                menuItem={menuItem[1]}/>}
                {/*Прокинуть функцию, которая меняет состояния для отображения формы*/}
                {show &&
                <Card dataSet={dataSet} info={""} buttonName={status} activeStatus={active} menuItem={menuItem[3]}/>
                }
                {!show &&
                <Card dataSet={dataSet} info={"Account"} buttonName="My account" activeStatus={active}
                      menuItem={menuItem[2]}/>
                }


                {/*<button onClick={()=>dataSet('from')}>function</button>*/}
                {/*<button onClick={()=>setinfo('from')}>function2</button>*/}
                {/*       Добавить компонент, для отображения информации по клику через Card*/}


            </div>



        </>)
}
