import React from "react";
import {Questions} from '../components/questions';
import ImageUploads from '../components/fotoupload';
import {Start} from "./Start";

export const Registration = ({data, position, }) => {

    const [name, setName] = React.useState('')
    const [sName, setSname] = React.useState('')
    let dataCard = (names) => {
        setName(names)
    }



    return (
        <div>
            <Start name={name} sname={sName}/>
            <Questions data={dataCard} startPosition={position} name={dataCard} sname={setSname} localname={name}/>
            {/*<h2>{name}</h2>*/}

        </div>
    );
}